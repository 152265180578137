.service-container {
  max-width: 1200px;
  width: calc(100% - 20px);
  display: flex;
  margin: 100px auto 30px auto;
  gap: 20px;
}

.service-container .quick-links {
  position: sticky;
  top: 95px;
  width: 300px;
  flex-shrink: 0;
  height: fit-content;
}

.service-container .content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px lightgray;
  height: fit-content;
}

.service-container .content p {
  font-family: Roboto;
}

.service-container .content ul,
.service-container .content ol {
  display: grid;
  gap: 10px;
  padding-left: 20px;
  font-family: Roboto;
}

.service-container .content li ul {
  margin-top: 10px;
}

.service-container .quick-links .accord.Mui-expanded {
  margin: 10px 0px;
}

.service-container .quick-links .sub-link {
  display: flex;
  flex-direction: column;
  padding: 0 0 10px 0px;
}

.service-container .quick-links .sub-link a {
  padding: 5px 0px;
  padding-left: 20px;
  color: black;
  text-decoration: none;
}

.service-container .quick-links .sub-link a:hover,
.service-container .quick-links .sub-link .active {
  background-color: var(--blue);
  color: white;
}

.service-container .quick-links .provinces {
  box-shadow: none;
}

.service-container .quick-links .provinces-header {
  padding-left: 20px;
  min-height: fit-content;
}

.service-container .quick-links .provinces-header .MuiAccordionSummary-content {
  margin: 5px 0px !important;
}

.service-container .quick-links .provinces-links {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.service-container .quick-links .provinces-links a {
  padding-left: 40px;
}

.service-container .content .MuiAccordionDetails-root {
  display: grid;
  gap: 10px;
}

@media (max-width: 900px) {
  .service-container {
    flex-direction: column;
  }

  .service-container .quick-links {
    width: 100%;
    position: relative;
    top: 0;
  }
}
