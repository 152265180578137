.navbar {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  height: 75px;
  padding: 0 30px;
  z-index: 10;
  color: white;
  transition: all 250ms ease-in;
}

.navbar.scrolled {
  background-color: var(--blue);
  box-shadow: 0 0 20px black;
}

.navbar .logo {
  height: 60px;
}

.navbar .pages {
  display: flex;
  list-style: none;
  margin-left: 20px;
  gap: 10px;
}

.navbar .pages a {
  color: inherit;
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  padding: 5px 10px;
}

.navbar .pages .active-route {
  color: var(--red);
  border-bottom: 4px solid var(--red);
}

.navbar .contacts {
  margin-left: auto;
  display: flex;
  gap: 20px;
}

.navbar .contacts .contact {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navbar .contacts .contact div {
  display: flex;
  flex-direction: column;
}

.navbar .contacts .contact div a {
  color: white;
  text-decoration: none;
}

.navbar .contacts .contact div a:hover {
  text-decoration: underline;
}

.navbar .contacts .contact svg {
  width: 35px;
  aspect-ratio: 1;
  fill: currentColor;
}

.navbar .contacts .contact .header {
  font-size: 18px;
}

@media (max-width: 900px) {
  .navbar {
    padding: 0px 15px;
    height: 65px;
  }

  .navbar .logo {
    height: 55px;
  }

  .navbar .menu-icon {
    height: 40px;
    width: 40px;
    margin-left: auto;
    fill: currentColor;
  }

  .menu .menu-container {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    gap: 10px;
  }

  .menu-links {
    width: 260px;
    padding: 10px 20px;
    font-family: Oswald;
    color: black;
    text-decoration: none;
    font-size: 25px;
    font-weight: 500;
  }

  .menu-links.active {
    background-color: #e9ecef;
    color: var(--maroon);
    font-weight: 700;
    font-size: 27px;
  }
}
