* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #e9ecef;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: Oswald;
}

:root {
  --red: #e6212a;
  --maroon: #b5161b;
  --blue: #012a4a;
}

.section {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
