.home-swiper {
  height: 100vh;
  width: 100%;
  box-shadow: 0px 0px 20px black;
}

.home-swiper .slide {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.home-swiper #slide-1 {
  background-image: url("https://images.unsplash.com/photo-1524187257221-4d3ffd2e68b8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover;
}

.home-swiper #slide-2 {
  background-image: url("https://images.unsplash.com/photo-1523050854058-8df90110c9f1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover;
}
.home-swiper #slide-3 {
  background-image: url("https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover;
}

.home-swiper .slide::before {
  position: absolute;
  top: 0;
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.slide .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 1;
  text-shadow: 0px 0px 20px black;
  text-align: center;
}

.slide .container h1 {
  font-size: 45px;
  animation: fade-in 500ms ease-in;
}

.slide .container p {
  opacity: 0;
  font-size: 45px;
  font-weight: 300;
  animation-name: fade-in;
  animation-delay: 500ms;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
}

.slide .container .buttons {
  opacity: 0;
  display: flex;
  gap: 30px;
  margin-top: 20px;
  animation-name: fade-in;
  animation-delay: 1000ms;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
}

.slide .container .buttons .button {
  font-family: Oswald;
  background-color: transparent;
  padding: 6px 15px;
  color: white;
  border: 3px solid white;
  text-decoration: none;
  font-size: 25px;
  border-radius: 5px;
  font-weight: 600;
  transition: all 250ms ease-in;
}

.slide .container .buttons .button:hover {
  background-color: red;
  scale: 1.05;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
  }
}

/* ---------------------About section -------------------------------- */

.home-about {
  padding: 50px 0px;
  background-color: var(--blue);
  box-shadow: 0px 0px 20px black;
}

.home-about .container {
  display: grid;
  grid-template-columns: 4fr 3fr;
  background-color: white;
  max-width: 800px;
  width: calc(100% - 20px);
  box-shadow: 0px 0px 20px black;
  border-radius: 5px;
  overflow: hidden;
}

.home-about .container .text {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 20px;
}

.home-about .container .text h1 {
  color: var(--maroon);
}

.home-about .container .text p {
  font-family: Roboto;
  font-size: 18px;
  line-height: 22px;
}

.home-about .container .image {
  background-image: url("https://images.unsplash.com/photo-1517935706615-2717063c2225?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover;
}

/* ----------------Service section ------------------------------ */

.home-service {
  padding: 100px 0px;
}

.home-service h1 {
  font-size: 35px;
  font-weight: 500;
}

.home-service .grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1000px;
  width: calc(100% - 20px);
  margin-top: 40px;
  gap: 20px;
}

.home-service .grid .service-card {
  position: relative;
  background-size: cover;
  box-shadow: 0px 0px 15px darkgray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  gap: 10px;
  transition: 250ms scale ease-in;
  color: white;
  overflow: hidden;
  min-height: 210px;
  opacity: 0;
  transform: translateY(20px);
}

.home-service .grid .service-card .hidden-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 250ms ease-in;
}

.home-service .grid .service-card::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.home-service .grid .service-card .header {
  font-size: 25px;
  font-weight: 600;
  z-index: 1;
  transition: all 250ms ease-in;
}

.home-service .grid .service-card .hidden-content .hidden-header {
  font-size: 20px;
  font-weight: 600;
  z-index: 1;
}

.home-service .grid .service-card .hidden-content .description {
  font-family: Roboto;
  transition: all 250ms ease-in;
}

.home-service .grid .service-card .hidden-content .read-more {
  width: fit-content;
  padding: 8px 15px;
  margin-left: auto;
  font-weight: 600;
  font-size: 16px;
  transition: all 150ms ease-in;
  cursor: pointer;
  border: 2px solid white;
  text-decoration: none;
  z-index: 1;
  color: white;
}

.home-service .grid .service-card:hover {
  scale: 1.04;
}

.home-service .grid .service-card:hover .header {
  opacity: 0;
}

.home-service .grid .service-card:hover .hidden-content {
  opacity: 1;
  pointer-events: all;
}

.home-service .grid .service-card .hidden-content .read-more:hover {
  background-color: var(--maroon);
}

/* ---------------------------------Reviews--------------------------------- */

.home-reviews {
  background-color: var(--blue);
  box-shadow: 0px 0px 20px black;
  padding: 50px 10px;
  z-index: 1;
}

.home-reviews .container .header {
  text-align: center;
  font-size: 36px;
  font-weight: 200;
  color: white;
  font-family: Roboto;
}

.home-reviews .container .header span {
  font-weight: 600;
  font-size: 40px;
  /* text-decoration: underline; */
}

.home-reviews .grid {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 3fr 2fr;
  max-width: 900px;
  gap: 15px;
}

.home-reviews .grid .review-card {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 40px;
  color: white;
}

.home-reviews .grid .review-card h3 {
  font-size: 30px;
  text-decoration: underline var(--maroon);
  text-underline-offset: 8px;
}

.home-reviews .grid .review-card span {
  font-size: 20px;
  font-weight: 300;
}

.home-reviews .grid .review-card p {
  font-family: Roboto;
}

.home-reviews .grid .review-swiper {
  width: 100%;
  border-right: 2px solid white;
  padding: 20px 0;
}

.home-reviews .grid .achievements {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 30px;
}

.home-reviews .grid .achievements div {
  color: white;
  text-align: center;
}

.home-reviews .grid .achievements div h1 {
  font-size: 50px;
}

.home-reviews .grid .achievements div p {
  font-family: Roboto;
  font-weight: 300;
}

.home-reviews .grid img {
  width: 90%;
}

/* ----------------------------------FAQ------------------------------------ */

.home-faq {
  padding: 50px 0px;
  background-color: #e9ecef;
  background-image: url("../../assets/map.png");
  background-size: cover;
  background-blend-mode: color-burn;
}

.home-faq .container {
  max-width: 900px;
  width: calc(100% - 10px);
  margin-top: 30px;
}

.home-faq .container .faq-accordion {
  margin-bottom: 10px;
  opacity: 0;
  transform: translateY(20px);
}

.home-faq .container .faq-accordion p {
  font-size: 18px;
}

@media (max-width: 600px) {
  .home-service .grid {
    grid-template-columns: 1fr;
  }

  .home-service .grid .service-card .hidden-content {
    opacity: 1;
  }

  .home-service .grid .service-card .header {
    opacity: 0;
  }

  .home-swiper .slide {
    background-position: 75%;
    animation: background-pan 4280ms ease-in-out infinite;
  }

  @keyframes background-pan {
    from {
      background-position: 25%;
    }
    to {
      background-position: 75%;
    }
  }

  .home-swiper .slide .container {
    gap: 10px;
  }

  .home-swiper .slide h1 {
    font-size: 35px;
  }
  .home-swiper .slide p {
    font-size: 35px;
  }

  .home-about .container {
    grid-template-columns: 1fr;
    grid-template-rows: 250px max-content;
  }

  .home-about .container .text p {
    font-size: 16px;
  }

  .home-about .container .image {
    grid-row: 1 / 1;
  }

  .home-service .grid .service-card {
    min-height: 250px;
  }

  .home-reviews .grid {
    grid-template-columns: 1fr;
  }

  .home-reviews .grid .review-swiper {
    border-right: none;
    border-bottom: 2px solid white;
  }

  .home-reviews .grid .review-card {
    padding: 20px 10px;
  }
}
