.footer {
  padding: 20px 0;
  margin-top: auto;
  background-color: var(--blue);
  box-shadow: 0px 0px 20px black;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.footer .container {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  gap: 60px;
  max-width: 800px;
  width: calc(100% - 20px);
}

.footer .container .column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer .container .contact {
  gap: 20px;
}

.footer .container .header {
  font-weight: 600;
  font-size: 25px;
}

.footer .container .address iframe {
  border: none;
  width: 100%;
  aspect-ratio: 16/9;
}

.footer .container .contact div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.footer .container .socials .social {
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 150ms ease-in;
  color: white;
  text-decoration: none;
}

.footer .container .socials .social svg {
  height: 30px;
  width: 30px;
  fill: currentColor;
  color: inherit;
}

.footer .container .socials .social:hover {
  scale: 1.1;
  color: var(--maroon);
}

@media (max-width: 600px) {
  .footer {
    padding: 20px 10px;
  }
  .footer .container {
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
  }

  .footer .container .address {
    grid-column: 1 / 3;
    border-bottom: 2px solid white;
    padding: 0px 0px 20px 0px;
    align-items: center;
  }

  .footer .container .contact {
    justify-content: space-between;
  }
}
